import type { FC } from 'react';
import React, { useState, useCallback, useMemo } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import DelegateFields from './delegate-fields';
import type { Field, FieldErrors, Fields } from '@wilm/shared-types/validation-rules/types';
import { fields as accountFields } from '@wilm/shared-types/validation-rules/account/addresses';
import type { Cart } from '@wilm/shared-types/cart';
import useSalesLinkProduct from 'frontastic/hooks/useSalesLinkProduct';
import { validate } from '@wilm/shared-types/validation-rules';

export interface Props {
    cart: Cart;
    customer: { firstName: string; lastName: string; email: string };
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (productsDelegates: ProductsDelegates) => Promise<void>;
}

type ProductsDelegates = Record<string, { name: string; delegates: Record<string, Fields> }>;
type DelegatesErrors = {
    hasErrors: boolean;
    errors: Record<string, Record<string, FieldErrors>>;
};

const DelegatesModal: FC<Props> = ({ cart, customer, isOpen, onClose, onSubmit }) => {
    const [fieldsErrors, setFieldsErrors] = useState<DelegatesErrors>({ hasErrors: false, errors: {} });
    const { isDelegate } = useSalesLinkProduct();

    const initialFields = useMemo(() => {
        return {
            firstName: accountFields.firstName,
            lastName: accountFields.lastName,
            email: accountFields.email
        } as Fields;
    }, []);

    const initialProductsDelegates: ProductsDelegates = useMemo(() => {
        return (
            cart.lineItems?.reduce((acc, lineItem) => {
                const customerIsDelegate = lineItem.isDelegate;

                if (isDelegate(lineItem.productTypeKey!, true)) {
                    const delegates = {} as Record<string, Fields>;
                    for (let i = 0; i < lineItem.count!; i++) {
                        if (customerIsDelegate && customer && i === 0) {
                            delegates[i] = {
                                firstName: {
                                    ...initialFields.firstName,
                                    value: customer.firstName,
                                    disabled: true
                                },
                                lastName: {
                                    ...initialFields.lastName,
                                    value: customer.lastName,
                                    disabled: true
                                },
                                email: {
                                    ...initialFields.email,
                                    value: customer.email,
                                    disabled: true
                                }
                            } as Fields;
                        } else {
                            delegates[i] = initialFields;
                        }
                    }
                    acc[lineItem.lineItemId] = {
                        name: lineItem.name!,
                        delegates
                    };
                }
                return acc;
            }, {} as ProductsDelegates) ?? ({} as ProductsDelegates)
        );
    }, [cart, initialFields, isDelegate]);

    const [productsDelegates, setProductsDelegates] = useState(initialProductsDelegates);

    const handleClose = () => {
        // setError('');
        // setData(initailData);
        onClose();
    };

    const validateDelegates = (productsDelegates: ProductsDelegates) => {
        const validateDelegatesResult: DelegatesErrors = {
            hasErrors: false,
            errors: {}
        };
        function validateDelegate(delegate: Fields): FieldErrors {
            const fieldErrors: FieldErrors = {};

            Object.entries(delegate).forEach(([fieldName, field]) => {
                const error = validate(field, delegate);
                if (Object.keys(error).length > 0) {
                    fieldErrors[fieldName] = error;
                }
            });

            return fieldErrors;
        }

        function validateProduct(product: { name: string; delegates: Record<string, Fields> }): Record<string, FieldErrors> {
            const productErrors: Record<string, FieldErrors> = {};

            Object.entries(product.delegates).forEach(([index, delegate]) => {
                const delegateErrors = validateDelegate(delegate);
                if (Object.keys(delegateErrors).length > 0) {
                    productErrors[index] = delegateErrors;
                }
            });

            return productErrors;
        }

        Object.entries(productsDelegates).forEach(([lineItemId, product]) => {
            const productErrors = validateProduct(product);

            if (Object.keys(productErrors).length > 0) {
                validateDelegatesResult.errors[lineItemId] = productErrors;
                validateDelegatesResult.hasErrors = true;
            }
        });

        return validateDelegatesResult;
    };

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            //validate productsDelegates

            const validateDelegatesResult = validateDelegates(productsDelegates);

            if (validateDelegatesResult.hasErrors) {
                setFieldsErrors(validateDelegatesResult);
            }

            void onSubmit(productsDelegates);
        },
        [onSubmit, productsDelegates]
    );

    const handleFieldChange = (field: Field, value: string, lineItemId: string, index: string) => {
        setProductsDelegates(prevProductsDelegates => {
            const newProductsDelegates: ProductsDelegates = {
                ...prevProductsDelegates,
                [lineItemId]: {
                    ...prevProductsDelegates[lineItemId],
                    delegates: {
                        ...prevProductsDelegates[lineItemId].delegates,
                        [index]: {
                            ...prevProductsDelegates[lineItemId].delegates[index],
                            [field.name]: {
                                ...prevProductsDelegates[lineItemId].delegates[index][field.name],
                                value
                            } as Field
                        }
                    }
                }
            };

            return newProductsDelegates;
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '1200px', maxHeight: 'calc(100% - 80px)', overflow: 'auto' } }}
            preventScroll={true}
        >
            <form autoComplete="off" noValidate onSubmit={e => handleSubmit(e)}>
                <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                    <h2>Learner Details</h2>
                    {productsDelegates &&
                        Object.entries(productsDelegates).map(([lineItemId, product]) => {
                            return (
                                <div key={lineItemId} className="mb-16">
                                    <p className="bg-gray-200 p-10 font-bold">{product.name}</p>

                                    {Object.entries(product.delegates).map(([index, field]) => {
                                        return (
                                            <DelegateFields
                                                key={index}
                                                fields={field}
                                                handleFieldChange={(field, value) => handleFieldChange(field, value, lineItemId, index)}
                                                fieldErrors={fieldsErrors.errors[lineItemId]?.[index]}
                                            />
                                        );
                                    })}
                                </div>
                            );
                        })}
                    <div className="flex flex-row-reverse gap-12">
                        <Button variant="primary" type="submit" className="rounded-md md:rounded-lg">
                            Create Order
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                handleClose();
                            }}
                            className="rounded-md md:rounded-lg"
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
};

export default DelegatesModal;
