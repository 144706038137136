import type { Account } from '@commercetools/frontend-domain-types/account/Account';
import type { AccountStatus } from '@wilm/shared-types/account/Account';
import type { Brand } from '@wilm/shared-types/base/Brand';
import type { OriginEnum } from '@wilm/shared-types/cart/OriginEnum';
import type { OktaCustomerResponse } from '@wilm/shared-types/okta/Account';
import type {
    Contact as CimContact
    // @ts-ignore
} from '@wilmingtongroup/cim-client-slim';
import type { AccountFieldErrors } from '../validation/interfaces/FieldErrors';

export type AccountExtended = Account & {
    countryCode?: string;
    errors?: AccountFieldErrors;
    isB2B?: boolean | string;
    status?: AccountStatus;
    phoneMobile?: string;
    jobTitle?: string;
    companyAddress1?: string;
    companyAddress2?: string;
    companyAddress3?: string;
    companyCity?: string;
    companyPostCode?: string;
    companyCountry?: string;
    accountPhone?: string;
    companyName?: string;
    vatId?: string;
    salesforceFinanceContact?: string;
    oktaAccountId: string;
    isCharity?: boolean;
    brand: Brand;
    confirmPassword?: string;
    token?: Token;
    tokenString?: string;
    reCaptchaToken?: string;
    globalErrors?: string;
    region?: string;
    cimAccountId?: string;
    cimContactId?: string;
    administrateContactId?: string;
    administrateAccountId?: string;
    salesforceContactNumber?: string;
    salesforceAccountName?: string;
    salesforceAccountDsNumber?: string;
    isExisting?: boolean;
    redirectToLogin?: boolean;
    existingSource?: LoginStates;
    salesforceAccountId?: string;
    salesforceContactId?: string;
    origin?: OriginEnum;
    statusOverride?: AccountStatus;
    isStaged?: boolean;
};
type Token = {
    tokenUpdated: boolean;
    alias: string;
    value: string;
    paymentToken: string;
    instrumentIdentifier: string;
    addressId: string;
    cardType: string;
    cardNumber: string;
    cardExpiryMonth: string;
    cardExpiryYear: string;
    currencyCode: string;
    merchantId: string;
};

export type DecodedTransientToken = {
    content: {
        paymentInformation: {
            card: {
                number: {
                    bin: string;
                    maskedValue: string;
                    detectedCardTypes: string[];
                };
                expirationMonth: {
                    value: string;
                };
                expirationYear: {
                    value: string;
                };
            };
        };
    };
};

export type AccountChangePassword = {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    [key: string]: string;
};

export interface UserExistCheckType {
    isError: boolean;
    showActivationModal: boolean;
    trimmedForm: boolean;
    showPassword: boolean;
}

export interface AllCustomerInformationType {
    cimContact?: CimContact;
    oktaUser?: OktaCustomerResponse;
    commercetoolsCustomer?: Account;
    isInactive?: boolean;
}

export enum LoginStates {
    SHOW_PASSWORD = 'showPassword',
    ACTIVATION_MODAL = 'showActivationModal',
    TRIMMED = 'trimmedForm',
    NONE = 'none'
}
