export interface FieldDefinition {
    name: string;
    disabled?: boolean;
    showOnPredicate?: (fields: Fields) => boolean; // If this field should be shown based on other fields
    validation: FieldDefinitionValidation;
}

interface FieldDefinitionValidation {
    required?: boolean;
    regex?: RegExp;
    minLength?: number;
    maxLength?: number;
    errorMessages?: {
        validation?: string;
        range?: string;
        regex?: string;
    };
    sameAsPredicate?: (fields: Fields) => boolean;
    requiredPredicate?: (fields: Fields) => boolean; // If this field is required based on other fields
    validate?: (field: StringFieldDefinition) => FieldErrors;
}
export type FieldErrors = {
    [key in keyof Fields]?: { showError?: boolean; message?: string };
};

export interface BooleanFieldDefinition extends FieldDefinition {
    type: FieldType.BOOLEAN;
    value: boolean;
}

export interface StringFieldDefinition extends FieldDefinition {
    type: FieldType.STRING;
    value: string;
}

export interface EnumFieldDefinition extends FieldDefinition {
    type: FieldType.ENUM;
    options: { label: string; value: string }[];
    getOptions?: (fields: Fields) => { label: string; value: string }[];
    value: string;
}

export enum FieldType {
    BOOLEAN = 'boolean',
    STRING = 'string',
    ENUM = 'enum'
}

export type Field = BooleanFieldDefinition | StringFieldDefinition | EnumFieldDefinition;

export type Fields = Record<string, Field>;
