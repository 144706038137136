import { useCallback, useMemo } from 'react';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { Cart } from '@wilm/shared-types/cart';
import type { CartInventoryCheckType, DelegatesData } from '@wilm/shared-types/cart/Cart';
import type { Discount } from '@wilm/shared-types/cart/Discount';
import type { MembershipAutoRenewalType, DelegateObjType } from '@wilm/shared-types/cart/LineItem';
import type { Order } from '@wilm/shared-types/cart/Order';
import type { Variant } from '@wilm/shared-types/product';
import type { Bundle } from '@wilm/shared-types/product/Product';
import { ProductTypeKey } from '@wilm/shared-types/product/Product';
import type { SessionSettingsResponseType } from '@wilm/shared-types/session/Session';
import cookie from 'js-cookie';
import useSWR, { mutate } from 'swr';
import type { SWRResponse } from 'swr';
import useI18n from 'helpers/hooks/useI18n';
import useSessionStorage from 'helpers/hooks/useSessionStorage';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useSession } from 'providers/session';
import { sdk } from 'sdk';
import { revalidateOptions } from 'frontastic';
import type { CartDetails, UseCartReturn, TermsError } from './types';

const placeOrderHandler = async (
    isTermsAccepted: boolean,
    membershipAutoRenewal?: MembershipAutoRenewalType,
    delegateObj?: DelegateObjType,
    sessionSettings?: SessionSettingsResponseType
): Promise<
    SDKResponse<{
        order: Order;
        umbracoSession: string;
    }>
> => {
    if (!sessionSettings?.sessionCookieName || !sessionSettings?.sessionCookieDomain) {
        throw new Error('Session settings are not set!');
    }
    const umbracoSessionCookie = cookie.get(sessionSettings.sessionCookieName);
    const payload: {
        isTermsAccepted: boolean;
        umbracoSession?: string;
        membershipAutoRenewal?: MembershipAutoRenewalType;
        delegateObj?: DelegateObjType;
    } = {
        isTermsAccepted,
        membershipAutoRenewal,
        delegateObj
    };
    if (umbracoSessionCookie) {
        payload.umbracoSession = umbracoSessionCookie;
    }
    const res = await sdk.callAction<{ order: Order; umbracoSession: string }>({ actionName: 'cart/checkout', payload });
    if (!res.isError) {
        cookie.set(sessionSettings.sessionCookieName, res.data.umbracoSession, { domain: sessionSettings.sessionCookieDomain });
    }

    return res;
};

const useCart = (): UseCartReturn => {
    const { membershipAutoRenewal: membershipAutoRenewalSettings } = useBrandSettingsContext();

    const [membershipAutoRenewal, setMembershipAutoRenewal] = useSessionStorage('membershipAutoRenewal', {} as MembershipAutoRenewalType);
    const [delegateObj, setDelegateObj] = useSessionStorage('delegateObj', {} as DelegateObjType);

    const { currency } = useI18n();

    const sessionSettings = useSession();

    const extensions = sdk.composableCommerce;
    const result: SWRResponse = useSWR('/action/cart/getCart', extensions.cart.getCart, revalidateOptions);

    const isLoading = result.isValidating;

    const shippingMethodsResults = { data: { data: [], isError: false } }; //useSWR('/action/cart/getShippingMethods', extensions.cart.getShippingMethods, revalidateOptions);

    if (!result.data?.isError) {
        if (!sessionSettings?.sessionCookieName || !sessionSettings?.sessionCookieDomain) {
            throw new Error('Session settings are not set!');
        }
        cookie.set(sessionSettings.sessionCookieName, result.data?.data.umbracoSession, { domain: sessionSettings.sessionCookieDomain });
    }

    const data = result.data?.isError ? {} : { data: result.data?.data.cart as Cart };

    const shippingMethods = shippingMethodsResults.data?.isError ? {} : { data: shippingMethodsResults.data?.data };

    const isEmpty = !data?.data?.lineItems?.length;

    const isShippingAccurate = !!data?.data?.shippingInfo;

    const reducedItems = useMemo(() => {
        return data?.data
            ? data?.data?.lineItems?.reduce(
                  (acc, curr) => {
                      const newAcc = { ...acc };

                      if (!curr.variant?.isOnStock) {
                          newAcc.hasOutOfStockItems = true;
                      }
                      if (!curr.variant?.attributes?.isShippingRequired) {
                          newAcc.hasShippingRequiredItem = true;
                      }
                      if (
                          curr.variant?.attributes?.isRenewable &&
                          curr.lineItemId &&
                          !curr.variant?.attributes?.isComplimentaryProduct &&
                          curr.price?.centAmount &&
                          curr.price?.centAmount > 0
                      ) {
                          newAcc.membershipAutoRenewal[curr.lineItemId] =
                              membershipAutoRenewal?.[curr.lineItemId] ?? membershipAutoRenewalSettings?.checkedByDefault ?? false;
                      }
                      if (
                          (curr.productTypeKey === ProductTypeKey.TRAINING ||
                              curr.productTypeKey === ProductTypeKey.MEMBERSHIP ||
                              curr.productTypeKey === ProductTypeKey.BUNDLE) &&
                          curr.lineItemId
                      ) {
                          newAcc.delegateObj[curr.lineItemId] = delegateObj?.[curr.lineItemId] ?? false;
                      }
                      if (!curr.parentBundleProductLineItemId) {
                          newAcc.totalItems += curr.count!;
                      }
                      return newAcc;
                  },
                  {
                      hasOutOfStockItems: false,
                      hasShippingRequiredItem: false,
                      totalItems: 0,
                      membershipAutoRenewal: {} as MembershipAutoRenewalType,
                      delegateObj: {} as DelegateObjType
                  }
              )
            : {
                  hasOutOfStockItems: false,
                  hasShippingRequiredItem: false,
                  totalItems: 0,
                  membershipAutoRenewal: {} as MembershipAutoRenewalType,
                  delegateObj: {} as DelegateObjType
              };
    }, [data?.data, membershipAutoRenewalSettings]);

    const transaction = useMemo(() => {
        const cartData = data.data;

        if (!cartData?.lineItems?.length)
            return {
                subtotal: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                discount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                tax: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                shipping: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                total: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                totalAmount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 }
            };

        const currencyCode = cartData.sum?.currencyCode ?? currency;
        const fractionDigits = cartData.sum?.fractionDigits ?? 2;

        const totalAmount = cartData.sum!.centAmount!;
        const subTotalAmount = cartData.lineItems.reduce((acc, curr) => acc + (curr.price?.centAmount ?? 0) * curr.count!, 0);

        const discountedAmount =
            cartData.lineItems.reduce(
                (acc, curr) => acc + ((curr.price?.centAmount ?? 0) * curr.count! - (curr.totalPrice?.centAmount ?? 0)),
                0
            ) + (cartData?.discountedAmount?.centAmount ?? 0);

        const totalTax = totalAmount > 0 ? cartData.taxed?.amount.centAmount ?? 0 : 0;

        const totalShipping =
            totalAmount > 0
                ? cartData.shippingInfo?.price?.centAmount ?? cartData.availableShippingMethods?.[0]?.rates?.[0]?.price?.centAmount ?? 0
                : 0;

        return {
            subtotal: {
                centAmount: subTotalAmount,
                currencyCode,
                fractionDigits
            },
            discount: {
                centAmount: discountedAmount,
                currencyCode,
                fractionDigits
            },
            shipping: {
                centAmount: totalShipping,
                currencyCode,
                fractionDigits
            },
            tax: {
                centAmount: totalTax,
                currencyCode,
                fractionDigits
            },
            total: {
                centAmount: totalAmount + totalTax,
                currencyCode,
                fractionDigits
            },
            totalAmount: {
                centAmount: totalAmount,
                currencyCode,
                fractionDigits
            }
        };
    }, [data.data, currency]);

    const addItem = useCallback(async (variant: Variant, quantity: number, bundle: Bundle, productTypeKey: ProductTypeKey) => {
        const extensions = sdk.composableCommerce;

        const payload = {
            variant: {
                sku: variant.sku,
                count: quantity
            },
            bundle: bundle,
            productTypeKey: productTypeKey
        };

        const res = await extensions.cart.addItem(payload);
        await mutate('/action/cart/getCart', res);
    }, []);

    const setDelegatesData = useCallback(async (delegatesData: DelegatesData) => {
        const payload = {
            delegatesData
        };

        const res: SDKResponse<Cart> = await sdk.callAction({ actionName: 'cart/setDelegatesData', payload });

        await mutate('/action/cart/getCart', res);
        return res.isError ? ({} as any) : res.data;
    }, []);

    const checkCartInventoryResponse = useSWR<SDKResponse<CartInventoryCheckType>>('action/cart/checkCartInventory', () =>
        sdk.callAction<CartInventoryCheckType>({ actionName: 'cart/checkCartInventory' })
    );

    const inventoryCheck = checkCartInventoryResponse.data?.isError
        ? ({ isError: true } as CartInventoryCheckType)
        : checkCartInventoryResponse.data?.data;

    const isCartInventoryAvailable = async () => {
        const checkCartInventoryResponse = await mutate('action/cart/checkCartInventory');

        if (!checkCartInventoryResponse?.isError && !checkCartInventoryResponse?.data?.hasOutOfStockItems) {
            return true;
        }
        return false;
    };

    const hasInvalidDiscountCodes = (cart: Cart) => {
        if (!cart) {
            return;
        }
        const invalidDiscountCodes = cart?.discountCodes?.filter(discountCode => discountCode.state === 'DoesNotMatchCart');
        const hasInvalidDiscountCodes = !!invalidDiscountCodes?.length;

        return hasInvalidDiscountCodes;
    };

    const removeInvalidDiscountCodes = useCallback(async () => {
        const res = await sdk.callAction({ actionName: 'cart/removeInvalidDiscountCodes' });

        await mutate('/action/cart/getCart', res);
    }, []);

    const orderCart = useCallback(
        async (isTermsAccepted: boolean, disableAutoRenewal?: boolean) => {
            const res = await placeOrderHandler(
                isTermsAccepted,
                disableAutoRenewal ? {} : reducedItems?.membershipAutoRenewal,
                reducedItems?.delegateObj ?? {},
                sessionSettings
            );
            await mutate('/action/cart/getCart');

            if (res.isError) {
                if (res.error.message === 'Error: termsNotAccepted') {
                    return { isError: true, error: { message: 'termsNotAccepted' } } as TermsError;
                }

                return { isError: true, error: res.error } as TermsError;
            }

            const order = res.data.order;
            setMembershipAutoRenewal({} as MembershipAutoRenewalType);
            setDelegateObj({} as DelegateObjType);
            return order;
        },
        [reducedItems?.membershipAutoRenewal, reducedItems?.delegateObj]
    );

    const orderHistory = useCallback(async () => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.cart.getOrderHistory();

        return res.isError ? ([] as Order[]) : (res.data as Order[]);
    }, []);

    const subscriptionHistory = useCallback(async () => {
        const res = await sdk.callAction({ actionName: 'cart/getSubscriptionsOrders' });

        return res.isError ? ([] as Order[]) : (res.data as Order[]);
    }, []);

    const setAutoMembership = useCallback(async () => {
        const res = await sdk.callAction({ actionName: 'cart/setAutoMembership' });

        await mutate('/action/cart/getCart', res);
    }, []);

    const getCartDiscountDescription = useCallback(async () => {
        const res = await sdk.callAction({ actionName: 'cart/getDiscountDescription' });
        return res.isError ? ([] as Discount) : (res.data as Discount);
    }, []);

    const getProjectSettings = useCallback(async () => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.project.getSettings();

        return res.isError ? {} : res.data;
    }, []);

    const removeItem = useCallback(async (lineItemId: string) => {
        const extensions = sdk.composableCommerce;

        const payload = {
            lineItem: { id: lineItemId }
        };

        const res = await extensions.cart.removeItem(payload);
        await mutate('/action/cart/getCart', res);
    }, []);

    const updateItem = useCallback(async (lineItemId: string, newQuantity: number) => {
        const extensions = sdk.composableCommerce;

        const payload = {
            lineItem: {
                id: lineItemId,
                count: newQuantity
            }
        };
        const res = await extensions.cart.updateItem(payload);
        await mutate('/action/cart/getCart', res);
        await mutate('action/cart/checkCartInventory');
    }, []);

    const updateCart = useCallback(async (payload: CartDetails): Promise<Cart> => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.cart.updateCart(payload);

        await mutate('/action/cart/getCart', res);

        return (res.isError ? {} : res.data) as Cart;
    }, []);

    const setShippingMethod = useCallback(async (shippingMethodId: string) => {
        const extensions = sdk.composableCommerce;

        const payload = {
            shippingMethod: {
                id: shippingMethodId
            }
        };

        const res = await extensions.cart.setShippingMethod(payload);

        await mutate('/action/cart/getCart', res);
    }, []);

    const redeemDiscountCode = useCallback(async (code: string) => {
        const extensions = sdk.composableCommerce;

        const payload = {
            code: code
        };
        const res = await extensions.cart.redeemDiscountCode(payload);

        if (!res.isError && (res.data as Cart).cartId) {
            await mutate('/action/cart/getCart', res);
        } else {
            throw new Error('code not valid');
        }
    }, []);

    const removeDiscountCode = useCallback(async (discount: Discount) => {
        const extensions = sdk.composableCommerce;

        const res = await extensions.cart.removeDiscountCode({ discountId: discount.discountId! });

        await mutate('/action/cart/getCart', res);
    }, []);

    const handleIsRenewableChange = useCallback(
        (lineItemId: string, isRenewable: boolean) => {
            setMembershipAutoRenewal({ ...membershipAutoRenewal, [lineItemId]: isRenewable });
        },
        [membershipAutoRenewal, setMembershipAutoRenewal]
    );

    const handleIsDelegateChange = useCallback(
        (lineItemId: string, isDelegate: boolean) => {
            const item = window?.sessionStorage.getItem('delegateObj');

            if (item) {
                const storedItem = JSON.parse(item);
                setDelegateObj({ ...storedItem, [lineItemId]: isDelegate });
            } else {
                setDelegateObj({ [lineItemId]: isDelegate });
            }
        },
        [delegateObj, setDelegateObj]
    );

    const validateDelegateSelection = useCallback(() => {
        const errors: string[] = [];

        const delegateObj = window?.sessionStorage.getItem('delegateObj');

        const itemsWithDelegate = data?.data?.lineItems?.filter(
            lineItem =>
                lineItem.productTypeKey === ProductTypeKey.TRAINING ||
                lineItem.productTypeKey === ProductTypeKey.MEMBERSHIP ||
                lineItem.productTypeKey === ProductTypeKey.BUNDLE
        );

        itemsWithDelegate?.map(item => {
            const isValid = delegateObj?.includes(item.lineItemId);

            if (!isValid) {
                errors.push(item.lineItemId);
            }
        });

        return errors;
    }, [data?.data?.lineItems]);

    return {
        ...data,
        isLoading,
        totalItems: reducedItems?.totalItems ?? 0,
        isEmpty,
        isShippingAccurate,
        hasOutOfStockItems: reducedItems?.hasOutOfStockItems ?? false,
        hasShippingRequiredItem: reducedItems?.hasShippingRequiredItem ?? false,
        transaction,
        membershipAutoRenewal: reducedItems?.membershipAutoRenewal ?? ({} as MembershipAutoRenewalType),
        delegateObj,
        inventoryCheck,
        handleIsRenewableChange,
        handleIsDelegateChange,
        isCartInventoryAvailable,
        hasInvalidDiscountCodes,
        addItem,
        updateCart,
        setShippingMethod,
        removeItem,
        updateItem,
        shippingMethods,
        orderCart,
        orderHistory,
        getProjectSettings,
        redeemDiscountCode,
        removeDiscountCode,
        subscriptionHistory,
        setAutoMembership,
        getCartDiscountDescription,
        taxed: data.data?.taxed,
        removeInvalidDiscountCodes,
        setDelegatesData,
        validateDelegateSelection
    };
};

export default useCart;
